{
  "name": "ipfs-core",
  "version": "0.8.0",
  "description": "JavaScript implementation of the IPFS specification",
  "keywords": [
    "IPFS"
  ],
  "homepage": "https://github.com/ipfs/js-ipfs/tree/master/packages/ipfs-core#readme",
  "bugs": "https://github.com/ipfs/js-ipfs/issues",
  "license": "(Apache-2.0 OR MIT)",
  "leadMaintainer": "Alex Potsides <alex@achingbrain.net>",
  "main": "src/index.js",
  "types": "dist/src/index.d.ts",
  "files": [
    "src",
    "dist",
    "!dist/*.tsbuildinfo"
  ],
  "browser": {
    "./src/runtime/init-assets-nodejs.js": "./src/runtime/init-assets-browser.js",
    "./src/runtime/config-nodejs.js": "./src/runtime/config-browser.js",
    "./src/runtime/dns-nodejs.js": "./src/runtime/dns-browser.js",
    "./src/runtime/libp2p-nodejs.js": "./src/runtime/libp2p-browser.js",
    "./src/runtime/libp2p-pubsub-routers-nodejs.js": "./src/runtime/libp2p-pubsub-routers-browser.js",
    "./src/runtime/preload-nodejs.js": "./src/runtime/preload-browser.js",
    "./src/runtime/repo-nodejs.js": "./src/runtime/repo-browser.js",
    "./test/utils/create-repo-nodejs.js": "./test/utils/create-repo-browser.js",
    "ipfs-utils/src/files/glob-source": false
  },
  "typesVersions": {
    "*": {
      "src/*": [
        "dist/src/*",
        "dist/src/*/index"
      ],
      "src/": [
        "dist/src/index"
      ]
    }
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/ipfs/js-ipfs.git"
  },
  "scripts": {
    "build": "aegir build",
    "lint": "aegir lint",
    "test": "aegir test",
    "test:node": "aegir test -t node",
    "test:browser": "aegir test -t browser",
    "test:webworker": "aegir test -t webworker",
    "test:electron-main": "aegir test -t electron-main",
    "test:electron-renderer": "aegir test -t electron-renderer",
    "test:bootstrapers": "IPFS_TEST=bootstrapers aegir test -t browser -f test/bootstrapers.js",
    "coverage": "nyc --reporter=text --reporter=lcov npm run test:node",
    "clean": "rimraf ./dist",
    "dep-check": "aegir dep-check -i interface-ipfs-core -i ipfs-core-types -i abort-controller"
  },
  "dependencies": {
    "abort-controller": "^3.0.0",
    "array-shuffle": "^2.0.0",
    "cborg": "^1.2.1",
    "cids": "^1.1.6",
    "dag-cbor-links": "^2.0.0",
    "datastore-core": "^4.0.0",
    "datastore-pubsub": "^0.6.1",
    "debug": "^4.1.1",
    "dlv": "^1.1.3",
    "err-code": "^3.0.1",
    "hamt-sharding": "^2.0.0",
    "hashlru": "^2.3.0",
    "interface-datastore": "^4.0.0",
    "ipfs-bitswap": "^5.0.3",
    "ipfs-block-service": "^0.19.0",
    "ipfs-core-types": "^0.5.2",
    "ipfs-core-utils": "^0.8.3",
    "ipfs-repo": "^9.1.6",
    "ipfs-unixfs": "^4.0.3",
    "ipfs-unixfs-exporter": "^5.0.3",
    "ipfs-unixfs-importer": "^7.0.3",
    "ipfs-utils": "^8.1.2",
    "ipld": "^0.30.0",
    "ipld-block": "^0.11.0",
    "ipld-dag-cbor": "^1.0.0",
    "ipld-dag-pb": "^0.22.1",
    "ipld-raw": "^7.0.0",
    "ipns": "^0.12.0",
    "is-domain-name": "^1.0.1",
    "is-ipfs": "^5.0.0",
    "it-all": "^1.0.4",
    "it-drain": "^1.0.3",
    "it-first": "^1.0.4",
    "it-last": "^1.0.4",
    "it-map": "^1.0.4",
    "it-pipe": "^1.1.0",
    "just-safe-set": "^2.2.1",
    "libp2p": "^0.31.6",
    "libp2p-bootstrap": "^0.12.3",
    "libp2p-crypto": "^0.19.3",
    "libp2p-floodsub": "^0.25.1",
    "libp2p-gossipsub": "^0.9.2",
    "libp2p-kad-dht": "^0.22.0",
    "libp2p-mdns": "^0.16.0",
    "libp2p-mplex": "^0.10.2",
    "libp2p-noise": "^3.1.0",
    "libp2p-record": "^0.10.3",
    "libp2p-tcp": "^0.15.4",
    "libp2p-webrtc-star": "^0.22.2",
    "libp2p-websockets": "^0.15.6",
    "mafmt": "^9.0.0",
    "merge-options": "^3.0.4",
    "mortice": "^2.0.0",
    "multiaddr": "^9.0.1",
    "multiaddr-to-uri": "^7.0.0",
    "multibase": "^4.0.2",
    "multicodec": "^3.0.1",
    "multihashing-async": "^2.1.2",
    "native-abort-controller": "^1.0.3",
    "p-queue": "^6.6.1",
    "parse-duration": "^1.0.0",
    "peer-id": "^0.14.1",
    "streaming-iterables": "^5.0.2",
    "uint8arrays": "^2.1.3"
  },
  "devDependencies": {
    "@types/dlv": "^1.1.2",
    "aegir": "^33.0.0",
    "delay": "^5.0.0",
    "go-ipfs": "0.8.0",
    "interface-ipfs-core": "^0.147.0",
    "ipfsd-ctl": "^8.0.1",
    "ipld-git": "^0.6.1",
    "iso-url": "^1.0.0",
    "nanoid": "^3.1.12",
    "p-defer": "^3.0.0",
    "rimraf": "^3.0.2",
    "sinon": "^10.0.1"
  },
  "gitHead": "81f944163f5a78ef15fdc23b2c488ba7f44af0be"
}
