{
  "name": "ipld-block",
  "version": "0.11.1",
  "description": "JavaScript Implementation of IPLD Block",
  "leadMaintainer": "Volker Mische <volker.mische@gmail.com>",
  "main": "src/index.js",
  "types": "dist/src/index.d.ts",
  "scripts": {
    "lint": "aegir lint",
    "check": "tsc --noEmit --noErrorTruncation",
    "test": "aegir test",
    "test:node": "aegir test --target node",
    "test:browser": "aegir test --target browser",
    "release": "aegir release --docs",
    "release-minor": "aegir release --type minor --docs",
    "release-major": "aegir release --type major --docs",
    "coverage": "aegir coverage",
    "coverage-publish": "aegir coverage --provider coveralls",
    "docs": "aegir docs",
    "prepare": "aegir build --no-bundle",
    "prepublishOnly": "aegir build"
  },
  "pre-push": [
    "lint",
    "test"
  ],
  "repository": {
    "type": "git",
    "url": "git+https://github.com/ipld/js-ipld-block.git"
  },
  "keywords": [
    "IPLD"
  ],
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/ipld/js-ipld-block/issues"
  },
  "homepage": "https://github.com/ipld/js-ipld-block#readme",
  "devDependencies": {
    "aegir": "^31.0.4",
    "uint8arrays": "^2.1.3"
  },
  "dependencies": {
    "cids": "^1.0.0"
  },
  "engines": {
    "node": ">=6.0.0",
    "npm": ">=3.0.0"
  },
  "contributors": [
    "David Dias <daviddias.p@gmail.com>",
    "Volker Mische <volker.mische@gmail.com>",
    "Friedel Ziegelmayer <dignifiedquire@gmail.com>",
    "Irakli Gozalishvili <contact@gozala.io>",
    "achingbrain <alex@achingbrain.net>",
    "ᴠɪᴄᴛᴏʀ ʙᴊᴇʟᴋʜᴏʟᴍ <victorbjelkholm@gmail.com>",
    "Alan Shaw <alan.shaw@protocol.ai>",
    "Charlie <the_charlie_daly@hotmail.co.uk>",
    "Diogo Silva <fsdiogo@gmail.com>",
    "Hugo Dias <hugomrdias@gmail.com>",
    "Mikeal Rogers <mikeal.rogers@gmail.com>",
    "Richard Littauer <richard.littauer@gmail.com>",
    "Richard Schneider <makaretu@gmail.com>",
    "Xmader <xmader@outlook.com>"
  ]
}
